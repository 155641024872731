import sweet from '../utils/sweet';
import actionsCodes from '../utils/actions_codes';
import constans from '../utils/constants';

export default {
    data: function() {
        return {
            ACTION_CODE: actionsCodes,
            MIX_CONSTANS: constans,
            pagination: {
                page: 1, 
                total: 1, 
                perpage: 10
            }, 
        }
    },

    methods: {

        toast: function(title, time, icon, position) {
            return sweet.toast(title, time, icon, position);
        },
        confirm: async function(title, text, confirmTextBtn, cancelTextBtn, icon) {
            return await sweet.confirm(title, text, confirmTextBtn, cancelTextBtn, icon);
        },
        toasterror(title, time, icon, position) {
            return sweet.failure(title, time, icon, position);
        }
        
    }
}