<template>
  <div class="px-5 py-3 min-h-full">
       <v-loading :is_loading="isloading" />
    <v-card >
        <v-card-text>


      <div class="grid gap-y-2">
        <div class="flex">
            <span class="text-gray-500 tracking-wide font-sans font-bold text-lg md:text-2xl text-center">A continuación se listan las entidades a las que sigues.</span>
        </div>
        <v-divider></v-divider>
      </div>
      <div class="mt-2 grid grid-cols-1 md:grid-cols-7 content-start gap-5 justify-self-auto justify-evenly gap-y-5 item-center">
          <div class="h-72 w-full md:w-56 bg-control-dark-background rounded-lg relative  hover:shadow-xl transition-all duration-200" v-for="(item, i) in entities" :key="i">
              <div :class="{'bg-gray-700': !item.active, 'bg-gray-300 hover:bg-gray-50': item.active}" class="h-1/2 bg-blend-multiply    rounded-tl-lg rounded-tr-lg bg-cover relative" :style="`background-image: url('`+item.CoverPhoto+`')`"></div>
               <div class="w-20 h-20 rounded-full border-2 border-gray-100 absolute top-1/3 right-5 flex justify-center items-center">
                <div class="w-16 h-16 bg-gray-300 rounded-full bg-cover" :style="`background-image: url('`+item.ProfilePicture+`')`"></div>
               </div>
                <router-link :to="`/${item.tagname}`">
                    <div class="mt-10 px-3 grid gap-y-5 cursor-pointer">
                            <label class="text-white font-sans font-bold text-2xl break-all overflow-ellipsis truncate cursor-pointer">{{item.name ? item.name : ''}}</label>
                            <div class="text-gray-400 text-sm flex justify-between mt-1">
                                <label>{{item.visits ? item.visits : '0'}} Visitas</label>
                                <label>{{item.reviews ? item.reviews : '0'}} Rebyu's</label>
                            </div>
                    </div>
                    </router-link>
              <div class="flex justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" @click="onSelected(i)" width="20" height="20" fill="currentColor" class="bi bi-chevron-compact-up text-white hover:text-gray-50 cursor-pointer" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M7.776 5.553a.5.5 0 0 1 .448 0l6 3a.5.5 0 1 1-.448.894L8 6.56 2.224 9.447a.5.5 0 1 1-.448-.894l6-3z"/>
                  </svg>

                    <div :class="{'bg-opacity-20 opacity-100 h-full': i == indexselected }" class="absolute bottom-0 opacity-0 bg-opacity-0 
                                bg-white h-0 rounded-lg transition-all duration-300  ease-in-out transform origin-bottom-left"  style="backdrop-filter: blur(5px);">
                       
                       <div class="flex justify-end px-2 py-1">
                           <svg xmlns="http://www.w3.org/2000/svg" @click="indexselected = null" class="h-5 w-5 text-gray-200 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                       </div>
                       <p class="text-gray-100 px-2 text-center">{{item.description ? item.description : ''}}</p>
                       <div class="flex justify-center gap-5">
                           <f-icon :icon="['fas', 'user-plus']" :class="{'text-gray-400': !item.followed, 'text-white': item.followed}" />
                           <f-icon :icon="['fas', 'thumbs-up']" :class="{'text-gray-400': !item.liked, 'text-white': item.liked}" />
                           <f-icon :icon="['fas', 'thumbs-down']" :class="{'text-gray-400': !item.disliked, 'text-white': item.disliked}" />
                       </div>
                    </div>
              </div>

          </div>
      </div>
     </v-card-text>
    </v-card>
  </div>
</template>

<script>
import entityFollowerService from '../../services/entity/entityfollower.service';
import ToastMixin from '@/mixins/vue/toastmixin';
import GlobalMixin from '@/mixins/globalMixin';

export default {
    mixins: [ToastMixin, GlobalMixin],
    data: function() {
        return {
            entities: [],
            isloading: false,
            indexselected: null
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init: async function() {
            this.isloading = true;
            await this.getFavorite();
            this.isloading = false;
        },
        getFavorite: async function() {
            let response = await entityFollowerService.favoriteByUser();
            if(response.success) {
                this.entities = response.data;
            }else {
                this.failure(response.message);
            }
        },
        onSelected: function(index) {
            this.indexselected = index;
        }
    }

}
</script>

<style scoped>


</style>